<style scoped type="text/css">
@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);
.lst-kix_list_14-1 > li:before {
  content: "o  ";
}
.lst-kix_list_14-3 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_14-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_14-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}
.lst-kix_list_14-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_14-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_14-6 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_9-3 {
  list-style-type: none;
}
ul.lst-kix_list_9-4 {
  list-style-type: none;
}
ul.lst-kix_list_9-2 {
  list-style-type: none;
}
ul.lst-kix_list_9-7 {
  list-style-type: none;
}
ul.lst-kix_list_9-8 {
  list-style-type: none;
}
ul.lst-kix_list_9-5 {
  list-style-type: none;
}
ul.lst-kix_list_9-6 {
  list-style-type: none;
}
ul.lst-kix_list_1-3 {
  list-style-type: none;
}
ul.lst-kix_list_1-4 {
  list-style-type: none;
}
ul.lst-kix_list_1-1 {
  list-style-type: none;
}
ul.lst-kix_list_1-2 {
  list-style-type: none;
}
ul.lst-kix_list_1-7 {
  list-style-type: none;
}
ul.lst-kix_list_9-0 {
  list-style-type: none;
}
ul.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_1-5 {
  list-style-type: none;
}
.lst-kix_list_14-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_1-6 {
  list-style-type: none;
}
.lst-kix_list_14-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_5-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_5-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_5-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_5-1 > li:before {
  content: "o  ";
}
.lst-kix_list_5-7 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_8-4 {
  list-style-type: none;
}
ul.lst-kix_list_8-5 {
  list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_5-8 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_8-2 {
  list-style-type: none;
}
ul.lst-kix_list_8-3 {
  list-style-type: none;
}
ul.lst-kix_list_8-8 {
  list-style-type: none;
}
ul.lst-kix_list_8-6 {
  list-style-type: none;
}
ul.lst-kix_list_8-7 {
  list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_5-5 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_8-0 {
  list-style-type: none;
}
.lst-kix_list_12-1 > li {
  counter-increment: lst-ctn-kix_list_12-1;
}
.lst-kix_list_6-1 > li:before {
  content: "o  ";
}
.lst-kix_list_6-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_6-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_6-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_7-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_7-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_7-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
.lst-kix_list_10-1 > li:before {
  content: "" counter(lst-ctn-kix_list_10-1, decimal) ". ";
}
.lst-kix_list_13-7 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ol.lst-kix_list_9-1 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_10-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_10-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_10-3 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_11-7 {
  list-style-type: none;
}
ul.lst-kix_list_11-6 {
  list-style-type: none;
}
.lst-kix_list_4-1 > li:before {
  content: "o  ";
}
ul.lst-kix_list_11-5 {
  list-style-type: none;
}
ul.lst-kix_list_11-4 {
  list-style-type: none;
}
ul.lst-kix_list_11-3 {
  list-style-type: none;
}
ul.lst-kix_list_11-2 {
  list-style-type: none;
}
ul.lst-kix_list_11-0 {
  list-style-type: none;
}
.lst-kix_list_9-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-5 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_11-8 {
  list-style-type: none;
}
.lst-kix_list_9-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_9-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_9-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-3 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_10-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_12-1.start {
  counter-reset: lst-ctn-kix_list_12-1 0;
}
.lst-kix_list_12-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_12-1 > li:before {
  content: "" counter(lst-ctn-kix_list_12-1, decimal) ". ";
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
.lst-kix_list_9-8 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
ol.lst-kix_list_8-1 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "o  ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
.lst-kix_list_11-7 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-0 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_13-3 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_10-8 {
  list-style-type: none;
}
ul.lst-kix_list_10-7 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_10-6 {
  list-style-type: none;
}
ul.lst-kix_list_10-5 {
  list-style-type: none;
}
ul.lst-kix_list_10-4 {
  list-style-type: none;
}
ul.lst-kix_list_10-3 {
  list-style-type: none;
}
.lst-kix_list_1-5 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_10-2 {
  list-style-type: none;
}
ol.lst-kix_list_9-1.start {
  counter-reset: lst-ctn-kix_list_9-1 0;
}
.lst-kix_list_13-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_12-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_12-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_13-1 > li:before {
  content: "o  ";
}
.lst-kix_list_9-1 > li {
  counter-increment: lst-ctn-kix_list_9-1;
}
.lst-kix_list_3-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_5-7 {
  list-style-type: none;
}
ul.lst-kix_list_5-8 {
  list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
  content: "o  ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_5-5 {
  list-style-type: none;
}
ul.lst-kix_list_5-6 {
  list-style-type: none;
}
ol.lst-kix_list_11-1 {
  list-style-type: none;
}
.lst-kix_list_8-1 > li:before {
  content: "" counter(lst-ctn-kix_list_8-1, decimal) ". ";
}
.lst-kix_list_8-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_3-5 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_5-0 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_5-3 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_5-4 {
  list-style-type: none;
}
ul.lst-kix_list_5-1 {
  list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_5-2 {
  list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_3-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_8-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-1 > li {
  counter-increment: lst-ctn-kix_list_11-1;
}
.lst-kix_list_8-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_8-3 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_13-5 {
  list-style-type: none;
}
ul.lst-kix_list_13-4 {
  list-style-type: none;
}
ul.lst-kix_list_13-3 {
  list-style-type: none;
}
.lst-kix_list_3-6 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_13-2 {
  list-style-type: none;
}
ul.lst-kix_list_13-1 {
  list-style-type: none;
}
.lst-kix_list_3-7 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_13-0 {
  list-style-type: none;
}
.lst-kix_list_8-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_13-8 {
  list-style-type: none;
}
.lst-kix_list_11-1 > li:before {
  content: "" counter(lst-ctn-kix_list_11-1, decimal) ". ";
}
ul.lst-kix_list_13-7 {
  list-style-type: none;
}
ul.lst-kix_list_13-6 {
  list-style-type: none;
}
.lst-kix_list_11-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_8-8 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}
.lst-kix_list_4-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-7 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_12-1 {
  list-style-type: none;
}
ul.lst-kix_list_4-8 {
  list-style-type: none;
}
ul.lst-kix_list_4-6 {
  list-style-type: none;
}
ul.lst-kix_list_4-7 {
  list-style-type: none;
}
ul.lst-kix_list_4-0 {
  list-style-type: none;
}
ul.lst-kix_list_4-1 {
  list-style-type: none;
}
ul.lst-kix_list_4-4 {
  list-style-type: none;
}
ul.lst-kix_list_4-5 {
  list-style-type: none;
}
ul.lst-kix_list_4-2 {
  list-style-type: none;
}
ul.lst-kix_list_4-3 {
  list-style-type: none;
}
ul.lst-kix_list_12-6 {
  list-style-type: none;
}
ul.lst-kix_list_12-5 {
  list-style-type: none;
}
ul.lst-kix_list_12-4 {
  list-style-type: none;
}
ul.lst-kix_list_12-3 {
  list-style-type: none;
}
ul.lst-kix_list_12-2 {
  list-style-type: none;
}
ul.lst-kix_list_12-0 {
  list-style-type: none;
}
ul.lst-kix_list_12-8 {
  list-style-type: none;
}
ul.lst-kix_list_12-7 {
  list-style-type: none;
}
.lst-kix_list_7-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_2-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_7-1 > li:before {
  content: "o  ";
}
.lst-kix_list_7-5 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_7-3 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}
ul.lst-kix_list_7-5 {
  list-style-type: none;
}
.lst-kix_list_10-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_7-6 {
  list-style-type: none;
}
ul.lst-kix_list_7-3 {
  list-style-type: none;
}
ul.lst-kix_list_7-4 {
  list-style-type: none;
}
.lst-kix_list_13-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_13-8 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_7-7 {
  list-style-type: none;
}
ul.lst-kix_list_7-8 {
  list-style-type: none;
}
ul.lst-kix_list_7-1 {
  list-style-type: none;
}
ul.lst-kix_list_7-2 {
  list-style-type: none;
}
ul.lst-kix_list_7-0 {
  list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
  content: "\0025aa   ";
}
ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}
.lst-kix_list_10-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_10-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_10-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_4-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_9-3 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_10-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_9-1 > li:before {
  content: "" counter(lst-ctn-kix_list_9-1, decimal) ". ";
}
.lst-kix_list_9-7 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_12-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_9-5 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_6-6 {
  list-style-type: none;
}
ul.lst-kix_list_6-7 {
  list-style-type: none;
}
ul.lst-kix_list_6-4 {
  list-style-type: none;
}
ul.lst-kix_list_6-5 {
  list-style-type: none;
}
ul.lst-kix_list_6-8 {
  list-style-type: none;
}
.lst-kix_list_12-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_11-6 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_1-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_6-2 {
  list-style-type: none;
}
.lst-kix_list_11-8 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_6-3 {
  list-style-type: none;
}
.lst-kix_list_1-2 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_6-0 {
  list-style-type: none;
}
.lst-kix_list_12-0 > li:before {
  content: "\0025cf   ";
}
ul.lst-kix_list_6-1 {
  list-style-type: none;
}
.lst-kix_list_1-4 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_13-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}
ul.lst-kix_list_14-4 {
  list-style-type: none;
}
ul.lst-kix_list_14-3 {
  list-style-type: none;
}
ul.lst-kix_list_14-2 {
  list-style-type: none;
}
.lst-kix_list_13-4 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_14-1 {
  list-style-type: none;
}
ul.lst-kix_list_14-0 {
  list-style-type: none;
}
.lst-kix_list_1-6 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_14-8 {
  list-style-type: none;
}
ul.lst-kix_list_14-7 {
  list-style-type: none;
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf   ";
}
.lst-kix_list_12-6 > li:before {
  content: "\0025aa   ";
}
ul.lst-kix_list_14-6 {
  list-style-type: none;
}
ul.lst-kix_list_14-5 {
  list-style-type: none;
}
.lst-kix_list_1-8 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_13-2 > li:before {
  content: "\0025aa   ";
}
.lst-kix_list_12-8 > li:before {
  content: "\0025aa   ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c3 {
  background-color: #ffffff;
  color: #111111;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: normal;
}
.c5 {
  background-color: #ffffff;
  color: #111111;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: normal;
}
.c13 {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: italic;
}
.c1 {
  color: #4c4d4f;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Calibri";
  font-style: normal;
}
.c0 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 21px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c7 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 21px;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 21px;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.c12 {
  background-color: #ffffff;
  font-family: "Calibri";
  color: #111111;
  font-weight: 400;
}
.c15 {
  font-family: "Calibri";
  font-style: italic;
  color: #ffffff;
  font-weight: 400;
}
.c10 {
  background-color: #ffffff;
  font-family: "Calibri";
  color: #111111;
  font-weight: 700;
}
.c8 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-style: normal;
}
.c2 {
  font-family: "Calibri";
  color: #4c4d4f;
  font-weight: 700;
}
.c9 {
  color: #000000;
  font-weight: 400;
  font-family: "Calibri";
}
.c14 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c4 {
  font-family: "Calibri";
  color: #4c4d4f;
  font-weight: 400;
}
.c6 {
  height: 12pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
li {
  color: #000000;
  font-size: 12pt;
  font-family: "Calibri";
}
p {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  font-family: "Calibri";
}
h1 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 13.5pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 7.5pt;
  padding-bottom: 0pt;
  font-family: "Times New Roman";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
<template>
  <Modal
    v-model="dialog"
    modalTitle="Termos de Uso"
    :cardTitle="true"
    :withClose="true"
    :modalFooter="true"
    myMaxWidth="640px"
    @input="$emit('close')"
  >
    <v-container fluid pa-0>
      <v-layout pa-0>
        <v-flex list-scroll sm12>
          <div class="content-text-block overflow-hidden" style="line-height: 21px !important;">
            <p class="c0"><span class="c8 c2">TERMO DE USO</span></p>
            <p class="c0">
              <span class="c1"
                >O uso do site https://abraceumacausa.com.br, doravante referido
                como Abrace Uma Causa (AUC) &eacute; inteiramente baseado no
                conte&uacute;do presente nestes termos. O usu&aacute;rio, ao
                utilizar da plataforma, concorda com todos os pontos aqui
                tratados.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c8 c2">Aceita&ccedil;&atilde;o dos termos</span>
            </p>
            <p class="c0">
              <span class="c1"
                >Leia atentamente os termos aqui presentes antes de utilizar os
                servi&ccedil;os oferecidos pela Abrace Uma Causa cadastrada com
                nome jur&iacute;dico de Abrace Uma Causa Gest&atilde;o e
                Servi&ccedil;os LTDA. Inscrita no CNPJ sob n&deg;
                26.246.070/0001-18.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2 c8">Defini&ccedil;&otilde;es iniciais</span>
            </p>
            <p class="c0">
              <span class="c1"
                >Para efeitos de did&aacute;tica, &eacute; importante que
                dividamos pap&eacute;is dentro do site:</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c4">&bull; </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c1"
                >: Consultoria especializada em conectar pessoas com interesse
                em realizar algum trabalho volunt&aacute;rio e se aproximar de
                causas sociais, com organiza&ccedil;&otilde;es, movimentos e
                coletivos sociais que precisam de volunt&aacute;rios para
                atividades ou projetos. Propriet&aacute;ria da plataforma
                digital, respons&aacute;vel por seu funcionamento e
                implanta&ccedil;&atilde;o, bem como pela indica&ccedil;&atilde;o
                e compartilhamento de atividades ou projetos via web. &Eacute; o
                operador dos dados dos usu&aacute;rios das a&ccedil;&otilde;es
                de voluntariado.</span
              >
            </p>
            <p class="c0">
              <span class="c4">&bull; </span
              ><span class="c2">Gestora da A&ccedil;&atilde;o</span
              ><span class="c1"
                >: Pessoa jur&iacute;dica que, atrav&eacute;s de termo de
                compromisso celebrado junto &agrave; AUC, autoriza a
                divulga&ccedil;&atilde;o das a&ccedil;&otilde;es de voluntariado
                e &eacute; controlador das informa&ccedil;&otilde;es dos
                usu&aacute;rios das a&ccedil;&otilde;es de voluntariado.</span
              >
            </p>
            <p class="c0">
              <span class="c4">&bull; </span
              ><span class="c2">Usu&aacute;rio/Volunt&aacute;rio</span
              ><span class="c1"
                >: Pessoa f&iacute;sica que, ao tomar ci&ecirc;ncia das
                a&ccedil;&otilde;es de voluntariado apoiadas/indicadas pela
                Gestora da A&ccedil;&atilde;o, tem a oportunidade de
                candidatar-se a participar das a&ccedil;&otilde;es de
                voluntariado. &Eacute; o titular dos seus dados armazenados na
                plataforma.</span
              >
            </p>
            <p class="c0">
              <span class="c4">&bull; </span
              ><span class="c2"
                >Institui&ccedil;&atilde;o / Fundo / Entidade Apoiada</span
              ><span class="c1"
                >: Organiza&ccedil;&atilde;o sem fins lucrativos &agrave; qual
                as atividades da a&ccedil;&atilde;o de voluntariado ser&atilde;o
                destinadas.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2"
                >1. Quais informa&ccedil;&otilde;es est&atilde;o presentes neste
                Termo de Uso?</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c4"
                >Neste Termo de Uso, o usu&aacute;rio do site
                https://abraceumacausa.com.br, doravante referido como </span
              ><span class="c2">Abrace Uma Causa (AUC)</span
              ><span class="c4"
                >&nbsp;encontrar&aacute; informa&ccedil;&otilde;es sobre: o
                funcionamento da Abrace Uma Causa e as regras aplic&aacute;veis
                a ela; o arcabou&ccedil;o legal relacionado &agrave; </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c4"
                >; as responsabilidades do usu&aacute;rio ao utilizar a
                plataforma; as responsabilidades da </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c1"
                >&nbsp;Gest&atilde;o e Servi&ccedil;os LTDA. Inscrita no CNPJ
                sob n&deg; 26.246.070/0001-18 ao prover o site;
                informa&ccedil;&otilde;es para contato, caso exista alguma
                d&uacute;vida ou seja necess&aacute;rio atualizar
                informa&ccedil;&otilde;es; e o foro respons&aacute;vel por
                eventuais reclama&ccedil;&otilde;es caso quest&otilde;es deste
                Termo de Uso tenham sido violadas.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c4"
                >Al&eacute;m disso, em nossa Pol&iacute;tica de Privacidade, o
                usu&aacute;rio da </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c1"
                >&nbsp;encontrar&aacute; informa&ccedil;&otilde;es sobre: qual o
                tratamento dos dados pessoais realizados, de forma automatizada
                ou n&atilde;o, e a sua finalidade; os dados pessoais dos
                usu&aacute;rios necess&aacute;rios para a
                presta&ccedil;&atilde;o do servi&ccedil;o; a forma como eles
                s&atilde;o coletados; se h&aacute; o compartilhamento de dados
                com terceiros; e quais as medidas de seguran&ccedil;a
                implementadas para proteger os dados.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2"
                >2. Aceita&ccedil;&atilde;o do Termo de Uso e Pol&iacute;tica de
                Privacidade</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c4">Ao utilizar a </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c4"
                >, o usu&aacute;rio confirma que leu e compreendeu os Termos e
                Pol&iacute;ticas aplic&aacute;veis &agrave; </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c1"
                >&nbsp;e concorda em ficar vinculado a eles.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2">3. Defini&ccedil;&otilde;es</span>
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c1"
                >Para melhor compreens&atilde;o deste documento, neste Termo de
                Uso e Pol&iacute;tica de Privacidade, consideram-se:</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Abrace Uma Causa:</span
              ><span class="c1"
                >&nbsp;Consultoria especializada em capta&ccedil;&atilde;o de
                recursos para entidades do terceiro setor. Propriet&aacute;ria
                da plataforma digital, respons&aacute;vel por seu funcionamento
                e implanta&ccedil;&atilde;o, bem como pelo gerenciamento dos
                recursos captados via web. &Eacute; o operador dos dados dos
                usu&aacute;rios das campanhas.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Agentes de tratamento</span
              ><span class="c1">: o controlador e o operador.</span>
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Autoridade nacional</span
              ><span class="c1"
                >: &oacute;rg&atilde;o da administra&ccedil;&atilde;o
                p&uacute;blica respons&aacute;vel por zelar, implementar e
                fiscalizar o cumprimento desta Lei em todo o territ&oacute;rio
                nacional.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">C&oacute;digos maliciosos</span
              ><span class="c1"
                >: &eacute; qualquer programa de computador, ou parte de um
                programa, constru&iacute;do com a inten&ccedil;&atilde;o de
                provocar danos, obter informa&ccedil;&otilde;es n&atilde;o
                autorizadas ou interromper o funcionamento de sistemas e/ou
                redes de computadores;</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Controlador:</span
              ><span class="c1"
                >&nbsp;pessoa natural ou jur&iacute;dica, de direito
                p&uacute;blico ou privado, a quem competem as decis&otilde;es
                referentes ao tratamento de dados pessoais.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Dado pessoal</span
              ><span class="c1"
                >: informa&ccedil;&atilde;o relacionada a pessoa natural
                identificada ou identific&aacute;vel.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Encarregado</span
              ><span class="c1"
                >: pessoa indicada pelo controlador e operador para atuar como
                canal de comunica&ccedil;&atilde;o entre o controlador, os
                titulares dos dados e a Autoridade Nacional de
                Prote&ccedil;&atilde;o de Dados (ANPD).</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Gestora da Campanha</span
              ><span class="c1"
                >: Pessoa jur&iacute;dica que, atrav&eacute;s de termo de
                compromisso celebrado junto &agrave; AUC, autoriza a
                divulga&ccedil;&atilde;o das campanhas de doa&ccedil;&otilde;es
                e &eacute; controlador das informa&ccedil;&otilde;es dos
                usu&aacute;rios da campanha.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2"
                >Institui&ccedil;&atilde;o / Fundo / Entidade Apoiada</span
              ><span class="c1"
                >: Organiza&ccedil;&atilde;o sem fins lucrativos &agrave; qual
                os recursos captados ser&atilde;o destinados. Possui projeto
                aprovado e vigente, nos termos das Leis de Incentivos Fiscais ou
                Causas escolhidas para apoio pela Gestora da Campanha.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Internet:</span
              ><span class="c1"
                >&nbsp;o sistema constitu&iacute;do do conjunto de protocolos
                l&oacute;gicos, estruturado em escala mundial para uso
                p&uacute;blico e irrestrito, com a finalidade de possibilitar a
                comunica&ccedil;&atilde;o de dados entre terminais por meio de
                diferentes redes;</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">S&iacute;tios e aplicativos</span
              ><span class="c1"
                >: s&iacute;tios e aplicativos por meio dos quais o
                usu&aacute;rio acessa os servi&ccedil;os e conte&uacute;dos
                disponibilizados;
              </span>
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Terceiro:</span
              ><span class="c1"
                >&nbsp;Pessoa ou entidade que n&atilde;o participa diretamente
                em um contrato, em um ato jur&iacute;dico ou em um
                neg&oacute;cio, ou que, para al&eacute;m das partes envolvidas,
                pode ter interesse num processo jur&iacute;dico.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Titular:</span
              ><span class="c1"
                >&nbsp;pessoa natural a quem se referem os dados pessoais que
                s&atilde;o objeto de tratamento.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Tratamento:</span
              ><span class="c1"
                >&nbsp;toda opera&ccedil;&atilde;o realizada com dados pessoais,
                como as que se referem a coleta, produ&ccedil;&atilde;o,
                recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o,
                utiliza&ccedil;&atilde;o, acesso, reprodu&ccedil;&atilde;o,
                transmiss&atilde;o, distribui&ccedil;&atilde;o, processamento,
                arquivamento, armazenamento, elimina&ccedil;&atilde;o,
                avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o,
                modifica&ccedil;&atilde;o, comunica&ccedil;&atilde;o,
                transfer&ecirc;ncia, difus&atilde;o ou
                extra&ccedil;&atilde;o.</span
              >
            </p>
            <p class="c0">
              <span class="c1"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Usu&aacute;rio/Doador/Volunt&aacute;rio:
                Pessoa f&iacute;sica que, ao tomar ci&ecirc;ncia dos projetos
                apoiados/indicados pela Gestora da Campanha, tem a oportunidade
                de efetivar doa&ccedil;&otilde;es a projetos aprovados pelas
                leis de incentivo fiscal, doa&ccedil;&otilde;es livres sem
                incentivo fiscal e participar de atividades de voluntariado,
                remotas ou presenciais. &Eacute; o titular dos seus dados
                armazenados na plataforma.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Uso compartilhado de dados</span
              ><span class="c1"
                >: comunica&ccedil;&atilde;o, difus&atilde;o,
                transfer&ecirc;ncia internacional, interconex&atilde;o de dados
                pessoais ou tratamento compartilhado de bancos de dados pessoais
                por &oacute;rg&atilde;os e entidades p&uacute;blicos no
                cumprimento de suas compet&ecirc;ncias legais, ou entre esses e
                entes privados, reciprocamente, com autoriza&ccedil;&atilde;o
                espec&iacute;fica, para uma ou mais modalidades de tratamento
                permitidas por esses entes p&uacute;blicos, ou entre entes
                privados.</span
              >
            </p>
            <p class="c0">
              <span class="c4"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c2">Viola&ccedil;&atilde;o de dados pessoais</span
              ><span class="c1"
                >: &eacute; uma viola&ccedil;&atilde;o de seguran&ccedil;a que
                provoque, de modo acidental ou il&iacute;cito, a
                destrui&ccedil;&atilde;o, a perda, a altera&ccedil;&atilde;o, a
                divulga&ccedil;&atilde;o ou o acesso n&atilde;o autorizado a
                dados pessoais transmitidos, conservados ou sujeitos a qualquer
                outro tipo de tratamento.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2"
                >4. Quais s&atilde;o as leis e normativos aplic&aacute;veis a
                Abrace Uma Causa?</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c1"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lei
                n&ordm; 12.965, de 23 de abril de 2014 Marco Civil da Internet:
                estabelece princ&iacute;pios, garantias, direitos e deveres para
                o uso da Internet no Brasil.</span
              >
            </p>
            <p class="c0">
              <span class="c1"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lei
                n&ordm; 13.709, de 14 de agosto de 2018: disp&otilde;e sobre a
                prote&ccedil;&atilde;o de dados pessoais.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2">5. O que &eacute; a Abrace Uma Causa?</span>
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c1"
                >A Abrace uma Causa &eacute; uma plataforma que facilita
                doa&ccedil;&otilde;es de pessoas f&iacute;sicas a projetos
                sociais.</span
              >
            </p>
            <p class="c0 c6"><span class="c1"></span></p>
            <p class="c0">
              <span class="c2">- Funcionalidade da Plataforma</span>
            </p>
            <p class="c0">
              <span class="c4"
                >Ap&oacute;s celebrado o contrato entre a Gestora da Campanha e </span
              ><span class="c2">Abrace Uma Causa</span
              ><span class="c1"
                >, os usu&aacute;rios poder&atilde;o acessar o site para
                realizar suas doa&ccedil;&otilde;es.</span
              >
            </p>
            <p class="c0 c6"><span class="c8 c2"></span></p>
            <p class="c0"><span class="c8 c2">Voluntariado:</span></p>
            <p class="c7">
              <span class="c5"
                >Selecionado o projeto / vaga / a&ccedil;&atilde;o que deseja
                participar, cadastra-se com todos os dados necess&aacute;rios ao
                cadastro na a&ccedil;&atilde;o e vincula&ccedil;&atilde;o do
                volunt&aacute;rio &agrave; a&ccedil;&atilde;o.
              </span>
            </p>
            <p class="c7">
              <span class="c5"
                >Para o monitoramento do projeto deve-se acessar a p&aacute;gina
                &ldquo;projetos&rdquo; e verificar seu andamento,
                pr&oacute;ximas atividades e calend&aacute;rio.</span
              >
            </p>
            <p class="c0 c6"><span class="c8 c2"></span></p>
            <p class="c0 c6"><span class="c8 c2"></span></p>
            <p class="c0"><span class="c8 c2">Doa&ccedil;&otilde;es:</span></p>
            <p class="c7">
              <span class="c5"
                >Selecionado o projeto / causa que deseja apoiar, cadastra-se
                com todos os dados necess&aacute;rios &agrave; emiss&atilde;o de
                recibo e vincula&ccedil;&atilde;o da doa&ccedil;&atilde;o ao
                CPF. As doa&ccedil;&otilde;es ser&atilde;o efetivadas pelas
                formas escolhidas / habilitadas pela Gestora da Campanha, que
                podem ser: i) desconto em folha de pagamento; ii) pagamento de
                Boleto; iii) cart&atilde;o de cr&eacute;dito; iv) d&eacute;bito
                autom&aacute;tico em conta corrente; v ) pix.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Para o monitoramento do projeto deve-se acessar a p&aacute;gina
                &ldquo;projetos&rdquo; e verificar como anda o sistema interno
                da Institui&ccedil;&atilde;o.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0"><span class="c3">- Abatimento Fiscal</span></p>
            <p class="c0">
              <span class="c5"
                >As Leis de Incentivo fiscal sobre doa&ccedil;&otilde;es
                funcionam para que a pessoa (f&iacute;sica ou jur&iacute;dica)
                escolha direcionar parte de seu imposto de renda para projetos
                sociais reconhecidos pelo governo.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Na sua declara&ccedil;&atilde;o de imposto de renda (no modo
                completo), &eacute; poss&iacute;vel informar as
                doa&ccedil;&otilde;es a projetos incentivados realizadas durante
                o ano-exerc&iacute;cio e, com isso, o sistema da Receita Federal
                do Brasil efetua o abatimento (dedu&ccedil;&atilde;o) ou
                devolu&ccedil;&atilde;o (restitui&ccedil;&atilde;o) dos valores
                doados.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >IMPORTANTE: a Abrace Uma Causa n&atilde;o garante o abatimento
                fiscal das doa&ccedil;&otilde;es realizadas, quando as
                inciativas financiadas permitirem tal mecanismo. &Eacute; dever
                do usu&aacute;rio organizar os documentos necess&aacute;rios
                &agrave; declara&ccedil;&atilde;o do Imposto de Renda e, quando
                poss&iacute;vel, dedu&ccedil;&atilde;o/restitui&ccedil;&atilde;o
                dos valores doados.</span
              >
            </p>
            <p class="c0">
              <span class="c10">- Campanhas N&atilde;o Incentivadas</span>
            </p>
            <p class="c0">
              <span class="c5"
                >A Gestora da Campanha poder&aacute; ter campanhas de
                doa&ccedil;&otilde;es n&atilde;o incentivadas, ou seja, essas
                doa&ccedil;&otilde;es n&atilde;o d&atilde;o direito ao
                Abatimento Fiscal. &Eacute; de responsabilidade da Pessoa
                F&iacute;sica se atentar a qual tipo de campanha est&aacute;
                fazendo sua doa&ccedil;&atilde;o.</span
              >
            </p>
            <p class="c0">
              <span class="c3">- Forma de Doa&ccedil;&atilde;o</span>
            </p>
            <p class="c0">
              <span class="c5"
                >As doa&ccedil;&otilde;es na plataforma da Abrace Uma Causa
                ocorrem por meio de pessoa f&iacute;sica que escolhe um projeto
                e define o valor de sua doa&ccedil;&atilde;o. Essa quantia
                dever&aacute; ser paga pela forma escolhida.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c5"
                >Declaro ci&ecirc;ncia que para as transa&ccedil;&otilde;es de
                CART&Atilde;O DE CR&Eacute;DITO, considerando principalmente sua
                finalidade social, n&atilde;o estar&aacute; pass&iacute;vel para
                pedido de &ldquo;CHARGEBACK&rdquo; junto a operadora / emissora
                do cart&atilde;o de cr&eacute;dito utilizado. Declaro ainda ser
                respons&aacute;vel pelo cart&atilde;o de cr&eacute;dito
                utilizado e estar ciente de que essa opera&ccedil;&atilde;o,
                pela sua natureza n&atilde;o est&aacute; pass&iacute;vel de
                estorno ou devolu&ccedil;&atilde;o ap&oacute;s o seu
                processamento, salvo em ocorr&ecirc;ncias fraudulentas,
                devidamente comprovadas.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c3">- Redirecionamento de Recursos</span>
            </p>
            <p class="c0">
              <span class="c5"
                >Caso a Institui&ccedil;&atilde;o e/ou projeto escolhido pelo
                doador n&atilde;o possa receber as doa&ccedil;&otilde;es, o
                contribuinte concorda que sua doa&ccedil;&atilde;o seja
                destinada um projeto similar.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >- Propriedade da Abrace Uma Causa e seus Conte&uacute;dos</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c5"
                >O logotipo e qualquer outro material utilizado nesse site
                pertence &agrave; Abrace Uma Causa, salvo o material produzido
                pelos institutos apoiados. N&atilde;o &eacute; permitido,
                portanto, que terceiros utilizem imagens e conte&uacute;do sem
                autoriza&ccedil;&atilde;o pr&eacute;via da Abrace Uma
                Causa.</span
              >
            </p>
            <p class="c0 c6"><span class="c3"></span></p>
            <p class="c0">
              <span class="c3"
                >6. Quais s&atilde;o os direitos do usu&aacute;rio do
                servi&ccedil;o?</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c12"
                >O usu&aacute;rio da Abrace Uma Causa possui os seguintes
                direitos, conferidos pela Lei de Prote&ccedil;&atilde;o de </span
              ><span class="c10">Dados Pessoais:</span>
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10"
                >Direito de confirma&ccedil;&atilde;o e acesso</span
              ><span class="c5"
                >&nbsp;(Art. 18, I e II): &eacute; o direito do usu&aacute;rio
                de obter da plataforma a confirma&ccedil;&atilde;o de que os
                dados pessoais que lhe digam respeito s&atilde;o ou n&atilde;o
                objeto de tratamento e, se for esse o caso, o direito de acessar
                os seus dados pessoais.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10">Direito de retifica&ccedil;&atilde;o </span
              ><span class="c5"
                >(Art. 18, III): &eacute; o direito de solicitar a
                corre&ccedil;&atilde;o de dados incompletos, inexatos ou
                desatualizados.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10"
                >Direito &agrave; limita&ccedil;&atilde;o do tratamento dos
                dados</span
              ><span class="c5"
                >&nbsp;(Art. 18, IV): &eacute; o direito do usu&aacute;rio de
                limitar o tratamento de seus dados pessoais, podendo exigir a
                elimina&ccedil;&atilde;o de dados desnecess&aacute;rios,
                excessivos ou tratados em desconformidade com o disposto na Lei
                Geral de Prote&ccedil;&atilde;o de Dados, quando
                aplic&aacute;vel e respeitado o cumprimento de
                obriga&ccedil;&atilde;o legal e regulat&oacute;ria pela Abrace
                Uma Causa, bem como os segredos comercial e industrial.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10">Direito de oposi&ccedil;&atilde;o</span
              ><span class="c5"
                >&nbsp;(Art. 18, &sect; 2&ordm;): &eacute; o direito do
                usu&aacute;rio de, a qualquer momento, se opor ao tratamento de
                dados por motivos relacionados com a sua situa&ccedil;&atilde;o
                particular, com fundamento em uma das hip&oacute;teses de
                dispensa de consentimento ou em caso de descumprimento ao
                disposto na Lei Geral de Prote&ccedil;&atilde;o de Dados.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10">Direito de portabilidade dos dados (</span
              ><span class="c5"
                >Art. 18, V): &eacute; o direito do usu&aacute;rio de realizar a
                portabilidade dos dados a outro fornecedor de servi&ccedil;o ou
                produto, mediante requisi&ccedil;&atilde;o expressa, de acordo
                com a regulamenta&ccedil;&atilde;o da autoridade nacional,
                observados os segredos comercial e industrial.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10"
                >Direito de n&atilde;o ser submetido a decis&otilde;es
                automatizadas</span
              ><span class="c5"
                >&nbsp;(Art. 20, LGPD): o titular dos dados tem direito a
                solicitar a revis&atilde;o de decis&otilde;es tomadas unicamente
                com base em tratamento automatizado de dados pessoais que afetem
                seus interesses, inclu&iacute;das as decis&otilde;es destinadas
                a definir o seu perfil pessoal, profissional, de consumo e de
                cr&eacute;dito ou os aspectos de sua personalidade.</span
              >
            </p>
            <p class="c0">
              <span class="c12"
                >&bull;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c10">Direito do respeito &agrave; intimidade</span
              ><span class="c5"
                >&nbsp;(Constitui&ccedil;&atilde;o Federal, Art. 5&ordm;, X) - O
                tratamento das informa&ccedil;&otilde;es pessoais deve ser feito
                de forma transparente e com respeito &agrave; intimidade, vida
                privada, honra e imagem das pessoas, bem como &agrave;s
                liberdades e garantias individuais.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c3"
                >7. Quais s&atilde;o as obriga&ccedil;&otilde;es dos
                usu&aacute;rios que utilizam a plataforma?</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O site da Abrace Uma Causa &eacute; uma plataforma direcionada
                a pessoas em plena capacidade. Menores de idade n&atilde;o gozam
                da possibilidade de uso.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Ao acessar a plataforma o indiv&iacute;duo deve manifestar seu
                consentimento por meio do aceito ao &ldquo;termo de
                anu&ecirc;ncia&rdquo; presente na confirma&ccedil;&atilde;o de
                doa&ccedil;&atilde;o</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c5"
                >O usu&aacute;rio se responsabiliza pela precis&atilde;o e
                veracidade dos dados informados e reconhece que a
                inconsist&ecirc;ncia destes poder&aacute; implicar a
                impossibilidade de se utilizar a Abrace Uma Causa. Durante a
                utiliza&ccedil;&atilde;o da plataforma, a fim de resguardar e de
                proteger os direitos de terceiros, o usu&aacute;rio se
                compromete a fornecer somente seus dados pessoais, e n&atilde;o
                os de terceiros e mant&ecirc;-los permanentemente
                atualizados.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O login e senha s&oacute; poder&atilde;o ser utilizados pelo
                usu&aacute;rio cadastrado. Ele se compromete em manter o sigilo
                da senha, que &eacute; pessoal e intransfer&iacute;vel,
                n&atilde;o sendo poss&iacute;vel, em qualquer hip&oacute;tese, a
                alega&ccedil;&atilde;o de uso indevido, ap&oacute;s o ato de
                compartilhamento.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O usu&aacute;rio da plataforma &eacute; respons&aacute;vel pela
                atualiza&ccedil;&atilde;o das suas informa&ccedil;&otilde;es
                pessoais e consequ&ecirc;ncias na omiss&atilde;o ou erros nas
                informa&ccedil;&otilde;es pessoais cadastradas.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O Usu&aacute;rio &eacute; respons&aacute;vel pela
                repara&ccedil;&atilde;o de todos e quaisquer danos, diretos ou
                indiretos (inclusive decorrentes de viola&ccedil;&atilde;o de
                quaisquer direitos de outros usu&aacute;rios, de terceiros,
                inclusive direitos de propriedade</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >&nbsp;intelectual, de sigilo e de personalidade), que sejam
                causados &agrave; Abrace Uma Causa, a qualquer outro
                Usu&aacute;rio, ou, ainda, a qualquer terceiro, inclusive em
                virtude do descumprimento do disposto nestes Termos de Uso e
                Pol&iacute;tica de Privacidade ou de qualquer ato praticado a
                partir de seu acesso &agrave; Internet e ao s&iacute;tio
                www.abraceumacausa.com.br.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c5"
                >A Abrace Uma Causa n&atilde;o poder&aacute; ser
                responsabilizada pelos seguintes fatos:</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >a. Equipamento infectado ou invadido por atacantes;</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >b. Equipamento avariado no momento do consumo de
                servi&ccedil;os;</span
              >
            </p>
            <p class="c0">
              <span class="c5">c. Prote&ccedil;&atilde;o do computador;</span>
            </p>
            <p class="c0">
              <span class="c5"
                >d. Prote&ccedil;&atilde;o das informa&ccedil;&otilde;es
                baseadas nos computadores dos usu&aacute;rios;</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >e. Abuso de uso dos computadores dos usu&aacute;rios;</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >f. Monitora&ccedil;&atilde;o clandestina do computador dos
                usu&aacute;rios;</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >g. Vulnerabilidades ou instabilidades existentes nos sistemas
                dos usu&aacute;rios;</span
              >
            </p>
            <p class="c0">
              <span class="c5">h. Per&iacute;metro inseguro.</span>
            </p>
            <p class="c0">
              <span class="c5"
                >Em nenhuma hip&oacute;tese, a Abrace Uma Causa ser&aacute;
                respons&aacute;vel pela instala&ccedil;&atilde;o no equipamento
                do Usu&aacute;rio ou de terceiros, de c&oacute;digos maliciosos
                (v&iacute;rus, trojans, malware, worm, bot, backdoor, spyware,
                rootkit, ou de quaisquer outros que venham a ser criados), em
                decorr&ecirc;ncia da navega&ccedil;&atilde;o na Internet pelo
                Usu&aacute;rio.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c3"
                >8. Quais s&atilde;o as responsabilidades da Abrace Uma Causa
                com meus dados?</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >A Abrace Uma Causa se compromete a cumprir todas as
                legisla&ccedil;&otilde;es inerentes ao uso correto dos dados
                pessoais do cidad&atilde;o de forma a preservar a privacidade
                dos dados utilizados na plataforma, bem como a garantir todos os
                direitos e garantias legais dos titulares dos dados. Ela
                tamb&eacute;m se obriga a promover, independentemente de
                requerimentos, a divulga&ccedil;&atilde;o em local de
                f&aacute;cil acesso, no &acirc;mbito de suas compet&ecirc;ncias,
                de informa&ccedil;&otilde;es de interesse coletivo ou geral por
                eles produzidas ou custodiadas, bem como implementar controles
                de seguran&ccedil;a para prote&ccedil;&atilde;o dos dados
                pessoais dos titulares.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >A Abrace Uma Causa poder&aacute;, quanto &agrave;s ordens
                judiciais de pedido das informa&ccedil;&otilde;es, compartilhar
                informa&ccedil;&otilde;es necess&aacute;rias para
                investiga&ccedil;&otilde;es ou tomar medidas relacionadas a
                atividades ilegais, suspeitas de fraude ou amea&ccedil;as
                potenciais contra pessoas, bens ou sistemas que sustentam a
                plataforma ou de outra forma necess&aacute;ria para cumprir com
                nossas obriga&ccedil;&otilde;es legais.
              </span>
            </p>
            <p class="c0">
              <span class="c5"
                >Caso ocorra, os titulares dos dados ser&atilde;o notificados,
                salvo quando o processo estiver em segredo de
                justi&ccedil;a.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >A Abrace Uma Causa toma todas as provid&ecirc;ncias para total
                prote&ccedil;&atilde;o de seus dados perante terceiros.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >A Abrace Uma Causa compromete-se em n&atilde;o fornecer
                informa&ccedil;&otilde;es ou dados pessoais para terceiros,
                salvo os necess&aacute;rios para a emiss&atilde;o do recibo por
                parte das Entidades e Fundos.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Os dados obtidos e coletados ser&atilde;o armazenados enquanto
                for Usu&aacute;rio da Abrace Uma Causa. Esse per&iacute;odo de
                armazenamento poder&aacute; ser revisto em alinhamento a
                qualquer nova disposi&ccedil;&atilde;o legal sobre prazo de
                reten&ccedil;&atilde;o. O titular pode requisitar
                informa&ccedil;&otilde;es sobre seus dados pessoais a qualquer
                momento.</span
              >
            </p>
            <p class="c0 c6"><span class="c3"></span></p>
            <p class="c0">
              <span class="c3">9. Este Termo de Uso pode ser alterado?</span>
            </p>
            <p class="c0">
              <span class="c5"
                >A presente vers&atilde;o deste Termo de Uso foi atualizada pela
                &uacute;ltima vez em: 02 de fevereiro de 2023. A Abrace Uma
                Causa se reserva o direito de modificar este documento a
                qualquer momento, especialmente para adapt&aacute;-lo &agrave;s
                evolu&ccedil;&otilde;es da Abrace Uma Causa, seja pela
                disponibiliza&ccedil;&atilde;o de novas funcionalidades, seja
                pela supress&atilde;o ou modifica&ccedil;&atilde;o daquelas
                j&aacute; existentes.</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Qualquer altera&ccedil;&atilde;o e/ou atualiza&ccedil;&atilde;o
                deste Termos de Uso passar&aacute; a vigorar a partir da data de
                sua publica&ccedil;&atilde;o no s&iacute;tio da Abrace Uma Causa
                e dever&aacute; ser integralmente observada pelos
                Usu&aacute;rios.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c3"
                >10. Qual o contato pelo qual o usu&aacute;rio da Abrace Uma
                Causa pode tirar suas d&uacute;vidas?</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O e-mail dpo@abraceumacausa.com.br &eacute; disponibilizado
                para que os titulares dos dados pessoais possam demandar as
                solicita&ccedil;&otilde;es previstas pelo art. 18&ordm; da LGPD
                e para sanar quaisquer d&uacute;vidas sobre este o
                servi&ccedil;o ou sobre este Termo de Uso.</span
              >
            </p>
            <p class="c0 c6"><span class="c3"></span></p>
            <p class="c0">
              <span class="c3"
                >11. Propriedade da Abrace Uma Causa e seus
                conte&uacute;dos</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >O logotipo e qualquer outro material utilizado nesse site
                pertence &agrave; Abrace Uma Causa, salvo o material produzido
                pelos institutos apoiados. N&atilde;o &eacute; permitido,
                portanto, que terceiros utilizem imagens e conte&uacute;do sem
                autoriza&ccedil;&atilde;o pr&eacute;via da Abrace Uma
                Causa.</span
              >
            </p>
            <p class="c0 c6"><span class="c3"></span></p>
            <p class="c0">
              <span class="c3"
                >12. Qual o foro aplic&aacute;vel &agrave; Abrace Uma
                Causa?</span
              >
            </p>
            <p class="c0">
              <span class="c5"
                >Este Termo ser&aacute; regido pela legisla&ccedil;&atilde;o da
                Rep&uacute;blica Federativa do Brasil. Qualquer
                reclama&ccedil;&atilde;o ou controv&eacute;rsia com base neste
                Termo ser&aacute; dirimida exclusivamente pela comarca de
                S&atilde;o Paulo-SP.</span
              >
            </p>
            <p class="c0 c6"><span class="c5"></span></p>
            <p class="c0">
              <span class="c4"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              ><span class="c15">Powered by&nbsp;Abrace uma Causa</span>
            </p>
            <p class="c11">
              <span class="c13"
                >Termos de uso&nbsp;|&nbsp;Pol&iacute;tica de Privacidade</span
              >
            </p>
            <p class="c6 c7"><span class="c8 c9"></span></p>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <template v-slot:footer>
      <v-container fluid>
        <v-layout>
          <v-flex text-xs-center>
            <v-btn
              flat
              round
              color="white"
              class="btn-primary px-5 text-uppercase"
              large
              @click="acceptConsentTerm"
            >Concordo</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </Modal>
</template>
<script type="plain/text">
import Modal from "@/components/Modal.vue";

export default {
  components: {
		Modal,
	},
	props: {
    value: "",
    consentTerms: "",
  },
	data() {
		return {
      dialog: false
		};
	},
  watch: {
    value: {
      handler: function () {
        this.dialog = this.value;
      },
      immediate: true,
    },
  },
  methods: {
    toggleDialog() {
			this.dialog = !this.dialog;
			this.$emit("input", this.dialog);
		},
    acceptConsentTerm() {
      this.$emit("accept");
    },
    close() {
      this.$emit("close");
    }
	}
};
</script>
