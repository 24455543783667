import BaseCrudService from "./baseCrud.service";

export default class VolunteeringRegistrationOfferService extends BaseCrudService {

    constructor() {
        super('VolunteeringRegistrationOffer');
    }

    findAllUsersRegistrationOffersToAttributeOffer(volunteeringAttributeOfferId) {
        return this.getRequest(`FindAllUsersRegistrationOffersToAttributeOffer/${volunteeringAttributeOfferId}`);
    }

    findUserRegistrationOffersToAttributeOffer(volunteeringAttributeOfferId) {
        return this.getRequest(`FindUserRegistrationOffersToAttributeOffer/${volunteeringAttributeOfferId}`, null, true, false);
    }

    findUserRegistrationRecurrencyGoogleCalendar(volunteeringAttributeOfferId){
        return this.getRequest(`FindUserRegistrationRecurrencyGoogleCalendar/${volunteeringAttributeOfferId}`);
    }

    findTotalRegistrationAmountByVolunteeringAttributeOfferId(volunteeringAttributeOfferId){
        return this.getRequest(`FindTotalRegistrationAmountByVolunteeringAttributeOfferId/${volunteeringAttributeOfferId}`);
    }

    findTotalRegistrationAmountByVolunteeringId(volunteeringId){
        return this.getRequest(`FindTotalRegistrationAmountByVolunteeringId/${volunteeringId}`);
    }

    registerPontualOffer(pontualTypes) {
        return this.postRequest('RegisterPontualOffer', pontualTypes);
    }

    registerRecurrentOffer(recurrentTypes) {
        return this.postRequest('RegisterRecurrentOffer', recurrentTypes);
    }

    findUserRegistrationOffersbyUserId (userId) {
        return this.getRequest(`FindUserRegistrationOffersbyUserId/${userId}`)
    }
    Delete (id) {
        return this.deleteRequest(`Delete/${id}`)
    }
    Restore (id) {
        return this.putRequest(`Restore/${id}`)
    }
}