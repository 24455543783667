<template>
  <v-app v-show="canShow">
    <v-snackbar v-model="showMessage" color="error" :timeout="6000" vertical="vertical" :bottom="false" :top="true"
      :right="true">
      {{ snackbarMessage }}
      <v-btn dark flat @click="showMessage = false">Fechar</v-btn>
    </v-snackbar>

    <v-snackbar v-model="showSuccessMessage" color="success" :timeout="16000" vertical="vertical" :bottom="false"
      :top="true" :right="true">
      {{ snackbarSuccessMessage }}
      <v-btn dark flat @click="showSuccessMessage = false">Fechar</v-btn>
    </v-snackbar>

    <v-snackbar v-model="showWarningMessage" color="warning" :timeout="16000" vertical="vertical" :bottom="false"
      :top="true" :right="true">
      {{ snackbarWarningMessage }}
      <v-btn dark flat @click="snackbarWarningMessage = false">Fechar</v-btn>
    </v-snackbar>

    <v-snackbar v-model="showInfoMessage" color="default" :timeout="16000" vertical="vertical" :bottom="false"
      :top="true" :right="true">
      {{ snackbarInfoMessage }}
      <v-btn dark flat @click="showInfoMessage = false">Fechar</v-btn>
    </v-snackbar>
    <v-loader v-show="showLoader" />
    <ModalLogin v-model="showLogin"></ModalLogin>
    <ModalPersonInfo v-model="showPersonInfo" @close="closePersonInfo()"></ModalPersonInfo>

    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script type="plain/text">
import ModalLogin from "@/components/login/ModalLogin.vue";
import ModalPersonInfo from "@/components/ModalPersonInfo.vue";
import CampaignService from "@/scripts/services/campaign.service.js";
import LoginService from "@/scripts/services/login.service.js";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import CommonHelper from "@/scripts/helpers/common.helper";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    ModalLogin,
    ModalPersonInfo
  },
  data() {
    return {
      snackbarMessage: "",
      showMessage: false,
      snackbarSuccessMessage: "",
      snackbarWarningMessage: "",
      snackbarInfoMessage: "",
      showSuccessMessage: false,
      showWarningMessage: false,
      showInfoMessage: false,
      campaignService: new CampaignService(),
      addedSkin: false,
      canShow: false,
      showLoader: false,
      campaignCssFile: null,
      campaignCssSkinFile: null,
      campaignCssFile: null,
      routesWithSkin: [
        { routeName: Routes.web.ProjectAll },
        { routeName: Routes.app.CampaignLandingPage },
        { routeName: Routes.web.CartLogged },
        { routeName: Routes.app.VolunteeringLP },
        { routeName: Routes.web.CheckoutDefault },
        { routeName: Routes.web.Login },
        { routeName: Routes.web.LoginGoogle },
        { routeName: Routes.web.LoginFacebook },
        { routeName: Routes.web.LoginPasswordRegistration },
        { routeName: Routes.web.LoginForgotPassword },
        { routeName: Routes.web.LoginVerifyAccount },
        { routeName: Routes.web.LoginVerifyPassword },
        { routeName: Routes.web.ProjectLP, includeOnProjectLP: true },
        { routeName: Routes.web.ProjectAccountabilityLP },
        {
          routeName: Routes.app.CampaignLandingPageEdit,
          includeWithoutSubdomain: true
        }
      ]
    };
  },
  created() {
    // console.log(
    // this.$vuetify
    // )
    // this.$vuetify.icons.next = "fal fa-home"
    // this.$vuetify.dataIterator.noResultsText ="Vai nessa"
    this.$store.watch(
      state => state.snackbarMessage,
      () => {
        const msg = this.$store.state.snackbarMessage;
        if (msg !== "") {
          this.showMessage = true;
          this.snackbarMessage = msg;
          this.$store.commit("SET_SNACKBAR_MESSAGE", "");
        }
      }
    );
    this.$store.watch(
      state => state.snackbarSuccessMessage,
      () => {
        const msg = this.$store.state.snackbarSuccessMessage;
        if (msg !== "") {
          this.showSuccessMessage = true;
          this.snackbarSuccessMessage = msg;
          this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "");
        }
      }
    );

    this.$store.watch(
      state => state.snackbarWarningMessage,
      () => {
        const msg = this.$store.state.snackbarWarningMessage;
        if (msg !== "") {
          this.showWarningMessage = true;
          this.snackbarWarningMessage = msg;
          this.$store.commit("SET_SNACKBAR_WARNING_MESSAGE", "");
        }
      }
    );

    this.$store.watch(
      state => state.snackbarInfoMessage,
      () => {
        const msg = this.$store.state.snackbarInfoMessage;
        if (msg !== "") {
          this.showInfoMessage = true;
          this.snackbarInfoMessage = msg;
          this.$store.commit("SET_SNACKBAR_INFO_MESSAGE", "");
        }
      }
    );
    this.$store.watch(
      state => state.showLoader,
      () => {
        this.showLoader = this.$store.state.showLoader;
      }
    );
    this.$store.watch(
      state => state.includeOrRemoveCssBasedOnRoute,
      this.storeWatch_setIncludeOrRemoveCssBasedOnRoute
    );
    this.buildCssFilesElement();
    this.storeWatch_setIncludeOrRemoveCssBasedOnRoute();
    this.campaignService.getMetaSubdomainAndOrProjectId(CommonHelper.getSubdomain())
      .then(data => {
        if (data && !data.activeCampaign) {
          this.$router.resolve({});
        }
      });
    this.campaignService.getSkin(this.getSkinCallback);
  },
  computed: {
    ...mapGetters(["showLogin", "showPersonInfo"])
  },
  methods: {
    buildCssFilesElement() {
      this.campaignCssFile = document.createElement("link");
      this.campaignCssFile.setAttribute("rel", "stylesheet");
      this.campaignCssFile.setAttribute("type", "text/css");
      this.campaignCssFile.setAttribute(
        "href",
        window.location.origin + "/campanha.css"
      );
      this.campaignCssFile.onload = this.show;

      this.projectCssFile = document.createElement("link");
      this.projectCssFile.setAttribute("rel", "stylesheet");
      this.projectCssFile.setAttribute("type", "text/css");
      this.projectCssFile.setAttribute(
        "href",
        window.location.origin + "/projeto.css"
      );
      this.projectCssFile.onload = this.show;

      this.campaignCssSkinFile = document.createElement("style");
      this.campaignCssSkinFile.setAttribute("type", "text/css");
    },
    storeWatch_setIncludeOrRemoveCssBasedOnRoute() {
      this.includeOrRemoveCssBasedOnRoute(
        this.$store.state.includeOrRemoveCssBasedOnRoute
      );
    },
    includeOrRemoveCssBasedOnRoute(to) {
      if (to.name == null) return;
      let currentSubdomain = CommonHelper.getSubdomain();
      let routeConfig = this.routesWithSkin.find(r => r.routeName == to.name);
      if (
        routeConfig != null &&
        ((routeConfig.hasOwnProperty("includeWithoutSubdomain") &&
          routeConfig.includeWithoutSubdomain) ||
          currentSubdomain != null)
      ) {
        //if (!this.addedSkin) {
        let head = document.getElementsByTagName("head")[0];
        if (
          routeConfig &&
          routeConfig.hasOwnProperty("includeOnProjectLP") &&
          routeConfig.includeOnProjectLP &&
          !head.contains(this.projectCssFile)
        ) {
          if (head.contains(this.campaignCssFile))
            head.removeChild(this.campaignCssFile);
          head.appendChild(this.projectCssFile);
        } else if (!head.contains(this.campaignCssFile)) {
          if (head.contains(this.projectCssFile))
            head.removeChild(this.projectCssFile);
          head.appendChild(this.campaignCssFile);
        }
        if (!head.contains(this.campaignCssSkinFile))
          head.appendChild(this.campaignCssSkinFile);
        this.addedSkin = true;
        //}
      } else {
        if (currentSubdomain) {
          let nextRoute = this.$router.resolve({
            name: to.name,
            query: to.query
          });

          // window.location.href =
          //   window.location.protocol +
          //   "//" +
          //   window.location.host.replace(currentSubdomain + ".", "") +
          //   nextRoute.href;
        }
        if (this.addedSkin) {
          let head = document.getElementsByTagName("head")[0];
          if (head.contains(this.campaignCssFile))
            head.removeChild(this.campaignCssFile);
          if (head.contains(this.projectCssFile))
            head.removeChild(this.projectCssFile);
          if (head.contains(this.campaignCssSkinFile))
            head.removeChild(this.campaignCssSkinFile);
          this.addedSkin = false;
        }
        this.show();
      }
    },
    show() {
      this.canShow = true;
    },
    closePersonInfo() {
      this.$store.commit("HIDE_PERSON_INFO");
    },
    getSkinCallback(data) {
      this.campaignCssSkinFile.innerHTML = data;
      this.includeOrRemoveCssBasedOnRoute(this.$route);
      // let routeConfig = this.routesWithSkin.find(
      //   r => r.routeName == this.$route.name
      // );
      // if (
      //   (routeConfig == null ||
      //     !(
      //       routeConfig.hasOwnProperty("includeWithoutSubdomain") &&
      //       routeConfig.includeWithoutSubdomain
      //     )) &&
      //   !data
      // ) {
      //   let head = document.getElementsByTagName("head")[0];
      //   if (head.contains(this.campaignCssFile))
      //     head.removeChild(this.campaignCssFile);
      //   if (head.contains(this.projectCssFile))
      //     head.removeChild(this.projectCssFile);
      //   if (head.contains(this.campaignCssSkinFile))
      //     head.removeChild(this.campaignCssSkinFile);
      // }
    }
  }
};
</script>