<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>Vaga de Voluntariado</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <StepsVolunteering :page-options="pageOptions" :volunteering="volunteering" />

          <div class="section list-view">
            <!-- section head -->
            <div class="head">
              <h2>PASSO 5</h2>
            </div>
            <v-flex v-if="selectedVolunteeringPontualTypes == null">
              <v-flex>
                <h2>Vagas de Voluntariado</h2>
              </v-flex>
              <div
                class="wrapper"
                v-for="(volunteeringAttributeOffer, index) in volunteeringAttributeOffers"
                :key="volunteeringAttributeOffer.id"
              >
                <!-- list item -->
                <div class="list-item">
                  <div>
                    {{ index + 1 }} -
                    {{ volunteeringAttributeOffer.volunteeringType.name }} -
                    {{ volunteeringAttributeOffer.offerName }}
                    <ul v-if="volunteeringAttributeOffer.volunteeringType.name == 'Pontual'">
                      <li
                        v-for="volunteeringPontualType in volunteeringAttributeOffer.volunteeringPontualTypes"
                        :key="volunteeringPontualType.id"
                      >
                        - {{ formatDate(volunteeringPontualType.volunteeringDate) }} /
                        {{ volunteeringPontualType.initialTime }} até
                        {{ volunteeringPontualType.finalTime }}
                      </li>
                    </ul>
                    <ul v-if="volunteeringAttributeOffer.volunteeringType.name == 'Recorrente'">
                      <li
                        v-for="volunteeringRecurrentType in volunteeringAttributeOffer.volunteeringRecurrentTypes"
                        :key="volunteeringRecurrentType.id"
                      >
                        -
                        {{ formatDate(volunteeringRecurrentType.volunteeringDateUntil) }} /
                        {{ volunteeringRecurrentType.weeklyHours }} horas
                        semanais -
                        {{ volunteeringRecurrentType.initialTime }} até
                        {{ volunteeringRecurrentType.finalTime }} |
                        <span
                          v-for="weekday in volunteeringRecurrentType.volunteeringRecurrentWeekDays"
                          v-bind:key="weekday.id"
                        >- {{ weekday.weekDayName }}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-if="volunteeringAttributeOffer.id > 0" class="action-bar">
                    <a v-on:click="openVolunteeringAttributeOfferEdit(volunteeringAttributeOffer)">
                      <i class="fal fa-edit"></i>
                    </a>
                    <a
                      v-on:click="openConfirmRemoveVolunteeringAttributeOfferModal(volunteeringAttributeOffer)"
                    >
                      <i class="fal fa-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="wrapper" v-if="volunteeringAttributeOffers.length == 0">
                <!-- list item -->
                <div class="list-item">
                  <div>Nenhuma vaga de voluntariado incluida</div>
                  <div class="action-bar"></div>
                </div>
              </div>
              <v-flex>
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary flex-inherit px-5"
                  large
                  v-on:click="addVolunteeringAttributeOffer()"
                >Adicionar</v-btn>
              </v-flex>
            </v-flex>
            <v-flex v-else>
              <v-flex xs4>
                <h2>Nova vaga de voluntariado</h2>
              </v-flex>
              <v-flex class="upload-box-profile-father testimony" shrink>
                <div
                  class="upload-image"
                  id="preview"
                  ref="preview"
                  :style="'backgroundImage:' + getImage()"
                >
                  <label></label>
                  <label
                    for="upload-attribute-offer"
                    class="do-upload"
                    v-show="selectedVolunteeringAttributeOffer.file.path == ''"
                  >
                    <i class="fal fa-cloud-upload"></i>Foto
                  </label>
                  <input
                    class="do-upload"
                    type="file"
                    id="upload-attribute-offer"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_attribute_offer"
                  />
                </div>
              </v-flex>
              <v-flex>
                <InputCheckBoxList
                  ref="checkboxList"
                  :list="volunteeringTypes"
                  :columns="2"
                  labelAttribute="name"
                  valueAttribute="name"
                  v-model="volunteeringTypeSelectedName"
                  @validationStatusChanged="validateStep"
                />
              </v-flex>
              <v-flex>
                <InputText
                  ref="nameInput"
                  type="text"
                  textLabel="Nome"
                  v-model="selectedVolunteeringAttributeOffer.offerName"
                  labelCharLimit="30"
                  invalidInputMessage="Preencha o nome da vaga"
                  @validationStatusChanged="validateStep"
                />

                <InputText
                  ref="totalOfferAmountInput"
                  type="tel"
                  textLabel="Número de Vagas"
                  v-model="selectedVolunteeringAttributeOffer.totalOfferAmount"
                  invalidInputMessage="Preencha o Número de Vagas"
                  @validationStatusChanged="validateStep"
                />

                <v-layout class="row">
                  <v-checkbox
                    color="#02ACB4"
                    class="ma-0"
                    v-model="
                      selectedVolunteeringAttributeOffer.isPresentialOportunityAllowed
                    "
                    label="Presencial"
                  ></v-checkbox>

                  <v-checkbox
                    color="#02ACB4"
                    class="ma-0"
                    v-model="
                      selectedVolunteeringAttributeOffer.isDistanceOportunityAllowed
                    "
                    label="Á distância"
                  ></v-checkbox>
                </v-layout>

                <div
                  class="t-area-heart-step"
                  v-if="
                    selectedVolunteeringAttributeOffer.isPresentialOportunityAllowed
                  "
                >
                  <InputText
                    ref="postalCodeInput"
                    v-model="
                      selectedVolunteeringAttributeOffer.address.postalCode
                    "
                    type="tel"
                    textLabel="CEP"
                    place-holder="CEP"
                    :showLoader="loaderInput"
                    invalidInputMessage="Preencha o CEP do local"
                    required="true"
                    mask="#####-###"
                    @input="searchCEP()"
                    @validationStatusChanged="validateStep"
                  />

                  <label v-if="cepValidation">Endereço:</label>
                  <span v-if="cepValidation">
                    {{
                      fullAddressDescription(selectedVolunteeringAttributeOffer)
                    }}
                  </span>

                  <InputText
                    type="text"
                    textLabel="Rua"
                    v-model="selectedVolunteeringAttributeOffer.address.street"
                    invalidInputMessage="Preencha a rua"
                    @validationStatusChanged="validateStep"
                  />

                  <InputText
                    type="tel"
                    textLabel="Nº*"
                    mask="#####"
                    v-model="selectedVolunteeringAttributeOffer.address.number"
                    invalidInputMessage="Preencha o Nº do Endereço"
                    @validationStatusChanged="validateStep"
                  />

                  <InputText
                    type="text"
                    textLabel="Bairro"
                    v-model="selectedVolunteeringAttributeOffer.address.neighborhood"
                    invalidInputMessage="Preencha o Bairro"
                    @validationStatusChanged="validateStep"
                  />

                  <InputText
                    type="text"
                    textLabel="Cidade"
                    v-model="selectedVolunteeringAttributeOffer.address.city"
                    invalidInputMessage="Preencha a Cidade"
                    @validationStatusChanged="validateStep"
                  />

                  <InputText
                    type="text"
                    textLabel="Estado"
                    v-model="selectedVolunteeringAttributeOffer.address.state"
                    invalidInputMessage="Preencha o Estado"
                    @validationStatusChanged="validateStep"
                  />

                  <InputText
                    type="text"
                    textLabel="Complemento"
                    v-model="selectedVolunteeringAttributeOffer.address.complement"
                    @validationStatusChanged="validateStep"
                  />
                </div>

                <div class="t-area-heart-step">
                  <label>Descreva a Função</label>
                  <InputTextArea
                    ref="textInput"
                    v-model="selectedVolunteeringAttributeOffer.function"
                    labelAreaCharLimit="500"
                    invalidInputMessage="Preencha a função"
                    @validationStatusChanged="validateStep"
                  ></InputTextArea>
                </div>
                <div class="t-area-heart-step">
                  <label>Descreva os pré-requisitos</label>

                  <vue-editor
                    :editor-toolbar="customToolbar"
                    v-model="selectedVolunteeringAttributeOffer.preRequisites"
                  />
                </div>
                <div class="t-area-heart-step" style="margin-top: 15px;">
                  <label>Termo de Aceite</label>

                  <vue-editor
                    :editor-toolbar="customToolbar"
                    v-model="selectedVolunteeringAttributeOffer.consentTerm"
                  />
                </div>
              </v-flex>

              <BorderSeparation />
              <v-flex v-if="selectedVolunteeringAttributeOffer.volunteeringType.name == 'Pontual'">
                <h2>Datas</h2>
                <v-layout
                  v-for="(selectedVolunteeringPontualType, index) in selectedVolunteeringPontualTypes"
                  :key="selectedVolunteeringPontualType.id"
                >
                  <v-flex mr-1>
                    <InputDate
                      class="mt-1"
                      v-model="selectedVolunteeringPontualType.volunteeringDate"
                      textLabel="Data Pontual"
                      placeholder="Data"
                      invalidInputMessage="Preencha a Data"
                      :min="$moment().add(1, 'days').format('YYYY-MM-DD')"
                      :disabled="selectedVolunteeringPontualType.id > 0"
                    ></InputDate>
                  </v-flex>
                  <v-flex mr-1>
                    <InputText
                      class="form-input"
                      type="tel"
                      v-model="selectedVolunteeringPontualType.initialTime"
                      textLabel="Inicio"
                      place-holder="Inicio"
                      invalidInputMessage="Preencha o Inicio"
                      required="true"
                      mask="##:##"
                      masked="true"
                      :disabled="selectedVolunteeringPontualType.id > 0"
                    />
                  </v-flex>
                  <v-flex mr-1>
                    <InputText
                      class="form-input"
                      type="tel"
                      v-model="selectedVolunteeringPontualType.finalTime"
                      textLabel="Fim"
                      place-holder="Fim"
                      invalidInputMessage="Preencha o fim"
                      required="true"
                      mask="##:##"
                      masked="true"
                      :disabled="selectedVolunteeringPontualType.id > 0"
                    />
                  </v-flex>
                  <v-flex mr-1 mt-1>
                    <v-btn
                      flat
                      round
                      color="white"
                      class="btn-primary mt-4 mx-1"
                      v-on:click="addVolunteeringPontualTypeDate()"
                      large
                    >
                      <i class="fal fa-plus"></i>
                    </v-btn>
                    <v-btn
                      flat
                      round
                      color="white"
                      class="btn-primary flex-inherit mt-4 mx-1"
                      large
                      v-on:click="removeVolunteeringPontualTypes(selectedVolunteeringPontualType, index)"
                      :disabled="index == selectedVolunteeringPontualTypes.length - 1 &&
                      selectedVolunteeringPontualType.id <= 0"
                    >
                      <i class="fal fa-trash"></i>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <BorderSeparation />
                <v-flex>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary px-5"
                    large
                    v-on:click="saveVolunteeringAttributeOffer()"
                  >Salvar</v-btn>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-on:click="cancelVolunteeringAttributeOffer()"
                  >Cancelar</v-btn>
                </v-flex>
              </v-flex>

              <v-flex
                v-if="selectedVolunteeringAttributeOffer.volunteeringType.name == 'Recorrente'"
              >
                <v-layout
                  :key="selectedVolunteeringRecurrentType.id"
                  v-for="(selectedVolunteeringRecurrentType, index) in selectedVolunteeringRecurrentTypes"
                >
                  <v-flex>
                    <v-layout>
                      <v-flex>
                        <InputDate
                          class="form-input"
                          v-model="
                            selectedVolunteeringRecurrentType.volunteeringDateUntil
                          "
                          textLabel="Data Limite"
                          place-holder="Até data limite ou indeterminado (dd/mm/yyyy)"
                          :min="$moment().add(1, 'days').format('YYYY-MM-DD')"                         
                        ></InputDate>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex mr-1>
                        <InputText
                          type="text"
                          textLabel="Número mínimo de horas de dedicação"
                          v-model="
                            selectedVolunteeringRecurrentType.minHoursDedication
                          "
                          invalidInputMessage="Preencha o Número mínimo de horas de dedicação"
                          mask="##"
                        />
                      </v-flex>
                      <v-flex mr-1>
                        <InputText
                          type="tel"
                          textLabel="Número máximo de horas de dedicação"
                          v-model="selectedVolunteeringRecurrentType.maxHoursDedication"
                          invalidInputMessage="Preencha o Número máximo de horas de dedicação"
                          mask="##"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex>
                        {{selectedVolunteeringRecurrentType.volunteeringRecurrentWeekDaysIds}}
                        <InputMultSelect
                          v-model="selectedVolunteeringRecurrentType.volunteeringRecurrentWeekDaysIds"
                          :items="weekDays"
                          textLabel="Dias da Semana"
                          helpLabel="Dias de Recorrência"
                          valueAttribute="id"
                          textAttribute="weekDayName"
                          maxShowItems="7"
                          :required="true"
                          :showAllOptions="true"
                        ></InputMultSelect>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs4 mr-1>
                        <InputText
                          class="form-input"
                          type="tel"
                          v-model="selectedVolunteeringRecurrentType.initialTime"
                          textLabel="Início"
                          place-holder="Início"
                          invalidInputMessage="Preencha o início"
                          required="true"
                          mask="##:##"
                          masked="true"
                        />
                      </v-flex>
                      <v-flex xs4>
                        <InputText
                          class="form-input"
                          type="tel"
                          v-model="selectedVolunteeringRecurrentType.finalTime"
                          textLabel="Fim"
                          place-holder="Fim"
                          invalidInputMessage="Preencha o fim"
                          required="true"
                          mask="##:##"
                          masked="true"
                        />
                      </v-flex>
                      <v-flex>
                        <!-- <v-btn
                          flat
                          round
                          color="white"
                          class="btn-primary flex-inherit px-5 ml-5 mt-4"
                          large
                          v-on:click="addVolunteeringRecurrentTypeDate()"
                        >
                          <i class="fal fa-plus"></i>
                        </v-btn>-->
                        <v-btn
                          flat
                          round
                          color="white"
                          class="btn-primary flex-inherit px-5 ml-5 mt-4"
                          large
                          v-on:click="removeVolunteeringRecurrentTypeDate(selectedVolunteeringRecurrentType, index)"
                        >
                          <i class="fal fa-trash"></i>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <BorderSeparation />
                  </v-flex>
                </v-layout>
                <v-flex>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-on:click="saveVolunteeringAttributeOffer()"
                  >Salvar</v-btn>
                  <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-on:click="cancelVolunteeringAttributeOffer()"
                  >Cancelar</v-btn>
                </v-flex>
              </v-flex>
            </v-flex>
          </div>
        </div>
      </div>
      <div class="box-help-spacer"></div>
      <HelpBox :text="text" effects="c_crop,g_face:auto,z_0.4,h_300,w_300" />
    </v-layout>
    <div class="footer-step" style="display: flex;">
      <button
        type="button"
        @click="nextPage()"
        class="btn-primary flex-inherit px-5 ml-auto v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
        v-if="selectedVolunteeringAttributeOffer == null"
      >
        <div class="v-btn__content">Concluir</div>
      </button>
    </div>

    <AucAlert
      ref="volunteeringAttributeOfferAlertComponent"
      title="Você tem certeza que deseja excluir essa vaga de voluntariado?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="
        deletevolunteeringAttributeOffer(selectedVolunteeringAttributeOffer)
      "
    />
  </div>
</template>

<script>
import BorderSeparation from "@/components/BorderSeparation.vue";
import HelpBox from "@/components/HelpBox.vue";
import VolunteeringService from "@/scripts/services/volunteering.service";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import {
  VolunteeringTypeEnum,
  VolunteeringTypes,
} from "@/scripts/models/enums/volunteeringType.enum.js";

import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxTextVolunteering } from "@/scripts/models/enums/helpBoxTextVolunteering.enum.js";
import FileService from "@/scripts/services/file.service";
import LocationService from "@/scripts/services/location.service";

import Volunteering from "@/scripts/models/volunteering.model";
import VolunteeringFile from "@/scripts/models/volunteeringFile.model";
import VolunteeringAttributeOffer from "@/scripts/models/volunteeringAttributeOffer.model";

import File from "@/scripts/models/file.model";

import AucAlert from "@/components/AucAlert";

import { VueEditor } from "vue2-editor";

import moment from "moment";

import { format } from "date-fns";

export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    AucAlert,
    BorderSeparation,
    VueEditor,
  },
  props: ["pageOptions"],
  data() {
    return {
      text: HelpBoxTextVolunteering.ProjectStep5,
      volunteeringService: new VolunteeringService(),
      volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
      autocompleteOptions: { componentRestrictions: { country: "br" }, },
      showFieldProduct: false,
      dialog: false,
      selectedDonationProduct: null,
      fileService: new FileService(),
      locationService: new LocationService(),

      volunteeringId: -1,
      volunteering: new Volunteering(),
      volunteeringTypeEnum: VolunteeringTypeEnum,
      volunteeringTypes: VolunteeringTypes,
      volunteeringAttributeOffers: [],
      volunteeringTypeSelectedName: "Pontual",

      selectedVolunteeringAttributeOfferId: null,
      selectedVolunteeringAttributeOffer: null,
      selectedVolunteeringPontualTypes: null,
      selectedVolunteeringRecurrentTypes: null,

      volunteeringRecurrentTypeConfigurations: {},

      selectedVolunteeringFileId: null,

      galleryLightboxVisible: false,
      galleryLightboxIndex: 0,

      routes: Routes,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projeto de Voluntariado" },
        { text: "Passo 5" },
      ],

      recurrencyType: "Remota",
      vacancyTypes: [{ name: "Remota" }, { name: "Presencial" }],

      loaderInput: false,
      cepValidation: false,

      weekDays: [
        { id: 1, weekDayName: "Segunda" },
        { id: 2, weekDayName: "Terça" },
        { id: 3, weekDayName: "Quarta" },
        { id: 4, weekDayName: "Quinta" },
        { id: 5, weekDayName: "Sexta" },
        { id: 6, weekDayName: "Sábado" },
        { id: 7, weekDayName: "Domingo" },
      ],
      selectedWeekDays: [],
      formIsOk: false
    };
  },
  watch: {
    volunteeringTypeSelectedName() {

      const selectedVolunteeringType = this.volunteeringTypes.find((v) => v.name == this.volunteeringTypeSelectedName);
      this.selectedVolunteeringAttributeOffer.volunteeringType = selectedVolunteeringType;

    },
    selectedVolunteeringRecurrentTypes() {

      if (this.selectedVolunteeringRecurrentTypes == null)
        return;

      // for (const volunteeringRecurrentType of this.selectedVolunteeringRecurrentTypes) {
      //   volunteeringRecurrentType.volunteeringRecurrentWeekDaysIds =
      //     this.weekDays
      //       .filter(weekDay => volunteeringRecurrentType.volunteeringRecurrentWeekDays
      //         .some(recurrentWeekDay => recurrentWeekDay.name == weekDay.name))
      //       .map(weekDay => weekDay.id);
      // }
    },
  },
  created() {
    if (!this.$route.query.volunteeringId) {
      CommonHelper.swal(
        "Voluntariado não informado",
        "O Voluntariado não foi informado"
      );
      this.$router.go(-1);
    }
    this.loadVolunteeringAttributeOffers(this.$route.query.volunteeringId);
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.pageOptions.currentPage = 5;
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
  },
  methods: {
    fullAddressDescription(volunteeringAttributeOffer) {
      if (
        volunteeringAttributeOffer.address.postalCode == "" ||
        volunteeringAttributeOffer.address.postalCode.length < 8
      )
        return "";

      const numero = volunteeringAttributeOffer.address.number
        ? volunteeringAttributeOffer.address.number
        : "[Insira o Nº]";

      return `${volunteeringAttributeOffer.address.street}, ${numero} - ${volunteeringAttributeOffer.address.neighborhood}, ${volunteeringAttributeOffer.address.city} - ${volunteeringAttributeOffer.address.state}`;
    },
    previousPage() {
      this.$router.push({
        name: this.routes.app.VolunteeringGallery,
        query: {
          volunteeringId: this.volunteering.id,
        },
      });
    },
    nextPage() {
      this.volunteeringService.save(this.volunteering).then((data) => {
        this.$router.push({
          name: this.routes.app.VolunteeringStep6,
          query: {
            volunteeringId: data.id,
          },
        });
      });
    },
    loadVolunteeringAttributeOffers(id) {
      this.volunteeringId = id;
      this.volunteeringService.findById(id).then((volunteering) => {
        this.volunteering = volunteering;

        this.volunteeringAttributeOfferService
          .findByVolunteeringId(id)
          .then(async (volunteeringAttributeOffers) => {
            this.volunteering.volunteeringAttributeOffers = volunteeringAttributeOffers;
            this.volunteeringAttributeOffers = volunteeringAttributeOffers;
            await this.checkForm()
          });
      });
    },
    getVolunteeringTypeName(type) {
      let vType = VolunteeringTypes.find((v) => v.id == type);
      if (vType) return vType.name;

      if (type.id > 0) {
        vType = VolunteeringTypes.find((v) => v.id == type.id);
        if (vType) return vType.name;
      }

      return "Não identificado";
    },
    addVolunteeringAttributeOffer() {
      this.selectedVolunteeringAttributeOffer = new VolunteeringAttributeOffer();
      this.selectedVolunteeringAttributeOffer.volunteeringType = JSON.parse(JSON.stringify(this.volunteeringTypes[0]));
      this.selectedVolunteeringAttributeOffer.volunteeringId = this.volunteeringId;
      this.selectedVolunteeringPontualTypes = [
        {
          volunteeringDate: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
          initialTime: "08:00",
          finalTime: "10:00",
        }];

      this.selectedVolunteeringRecurrentTypes = [
        {
          volunteeringDateUntil: moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
          weeklyHours: 30,
          minHoursDedication: 1,
          maxHoursDedication: 2,
          initialTime: "08:00",
          finalTime: "10:00",
          volunteeringRecurrentWeekDays: JSON.parse(JSON.stringify(this.weekDays)),
          volunteeringRecurrentWeekDaysIds: this.weekDays.map((q) => q.id),
        },
      ];

      this.volunteeringTypeSelectedName = "Pontual";
    },
    openVolunteeringAttributeOfferEdit(volunteeringAttributeOffer) {
      this.selectedVolunteeringPontualTypes = volunteeringAttributeOffer.volunteeringPontualTypes;
      this.selectedVolunteeringRecurrentTypes = volunteeringAttributeOffer.volunteeringRecurrentTypes;

      for (var index = 0; index < this.selectedVolunteeringRecurrentTypes.length; index++) {
        this.selectedVolunteeringRecurrentTypes[index].volunteeringRecurrentWeekDaysIds =
          this.selectedVolunteeringRecurrentTypes[index].volunteeringRecurrentWeekDays.map((q) => this.weekDays.find(x => x.weekDayName == q.weekDayName).id);

      }

      if (volunteeringAttributeOffer.file == null)
        volunteeringAttributeOffer.file = new File();

      this.selectedVolunteeringAttributeOffer = volunteeringAttributeOffer;

      if (this.selectedVolunteeringPontualTypes.length == 0)
        this.addVolunteeringPontualTypeDate();

      if (this.selectedVolunteeringRecurrentTypes.length == 0)
        this.addVolunteeringRecurrentTypeDate();

      this.volunteeringTypeSelectedName = volunteeringAttributeOffer.volunteeringType.name;
    },
    openConfirmRemoveVolunteeringAttributeOfferModal(volunteeringAttributeOffer) {
      this.selectedVolunteeringAttributeOffer = volunteeringAttributeOffer;
      this.$refs.volunteeringAttributeOfferAlertComponent.open();
    },
    deletevolunteeringAttributeOffer(volunteeringAttributeOffer) {
      this.volunteeringAttributeOfferService.delete(volunteeringAttributeOffer.id)
        .then(() => this.loadVolunteeringAttributeOffers(this.volunteeringId));
    },
    async saveVolunteeringAttributeOffer() {
      if (this.selectedVolunteeringAttributeOffer.volunteeringType.name == "Pontual") {
        if (this.selectedVolunteeringAttributeOffer != null) {
          this.selectedVolunteeringAttributeOffer.volunteeringPontualTypes = this.selectedVolunteeringPontualTypes;
          this.volunteeringAttributeOfferService
            .save(this.selectedVolunteeringAttributeOffer)
            .then(() => this.loadVolunteeringAttributeOffers(this.volunteeringId));
        }
      }
      else if (this.selectedVolunteeringAttributeOffer.volunteeringType.name == "Recorrente") {
        if (this.selectedVolunteeringAttributeOffer != null) {

          this.selectedVolunteeringRecurrentTypes.forEach(recurrency => {

            //caso seja edição, não é possível modificar os dias da semana, caso isso se torne requisito
            //deverá ser modificado a lógica de salvar dias da semana no back-end tbm

            if (recurrency.id > 0)
              return true;

            var selected = [];

            this.weekDays.forEach(weekDay => {

              if (!recurrency.volunteeringRecurrentWeekDaysIds.includes(weekDay.id))
                return true;

              let selectedWeekDay = JSON.parse(JSON.stringify(weekDay));
              selectedWeekDay.id = 0;
              selected.push(selectedWeekDay);

            });

            recurrency.volunteeringRecurrentWeekDays = selected;

          });

          for (var index = 0; index < this.selectedVolunteeringRecurrentTypes.length; index++) {
            var selected = [];

            for (var weekIndex = 0; weekIndex < this.weekDays.length; weekIndex++) {
              if (this.selectedVolunteeringRecurrentTypes[index].volunteeringRecurrentWeekDaysIds.includes(this.weekDays[weekIndex].id)) {
                let selectedWeekDay = JSON.parse(JSON.stringify(this.weekDays[weekIndex]));
                selectedWeekDay.id = 0;

                selected.push(selectedWeekDay);
              }
            }

            this.selectedVolunteeringRecurrentTypes[index].volunteeringRecurrentWeekDays = selected;
          }

          this.selectedVolunteeringAttributeOffer.volunteeringRecurrentTypes = this.selectedVolunteeringRecurrentTypes;
          this.volunteeringAttributeOfferService
            .save(this.selectedVolunteeringAttributeOffer)
            .then(() => this.loadVolunteeringAttributeOffers(this.volunteeringId));
        }
      }

      this.selectedVolunteeringAttributeOffer = null;
      this.selectedVolunteeringPontualTypes = null;
      this.selectedVolunteeringRecurrentTypes = null;
      await this.checkForm()
    },
    cancelVolunteeringAttributeOffer() {
      this.selectedVolunteeringPontualTypes = null;
      this.selectedVolunteeringRecurrentTypes = null;
      this.selectedVolunteeringAttributeOffer = null;

    },
    addVolunteeringPontualTypeDate() {
      if (this.selectedVolunteeringPontualTypes.length == 0) {
        var volunteeringDate = moment(new Date()).add(1, "days").format("YYYY-MM-DD");

        this.selectedVolunteeringPontualTypes.push({
          volunteeringDate: volunteeringDate,
          initialTime: "08:00",
          finalTime: "10:00",
        });
      }
      else {
        this.selectedVolunteeringPontualTypes.push({
          volunteeringDate: moment(
            this.selectedVolunteeringPontualTypes[
              this.selectedVolunteeringPontualTypes.length - 1
            ].volunteeringDate,
            "YYYY-MM-DD"
          )
            .add(1, "days")
            .format("YYYY-MM-DD"),
          initialTime: "08:00",
          finalTime: "10:00",
        });
      }

    },
    removeVolunteeringPontualTypes(volunteeringPontualType, index) {
      if (volunteeringPontualType.id > 0)
        this.volunteeringAttributeOfferService
          .deleteVolunteeringPontualType(volunteeringPontualType.id)
          .then(() => {
            this.volunteeringAttributeOfferService.findByVolunteeringId(this.volunteeringId)
              .then((volunteeringAttributeOffers) => {

                // this.volunteering.volunteeringAttributeOffers = volunteeringAttributeOffers;
                // this.volunteeringAttributeOffers = volunteeringAttributeOffers;

                // this.selectedVolunteeringAttributeOffer = this.volunteeringAttributeOffers.find( (v) => v.id == this.selectedVolunteeringAttributeOffer.id);
                // this.selectedVolunteeringPontualTypes = this.selectedVolunteeringAttributeOffer.volunteeringPontualTypes;
                // this.selectedVolunteeringRecurrentTypes = this.selectedVolunteeringAttributeOffer.volunteeringRecurrentTypes;

                this.selectedVolunteeringPontualTypes.splice(index, 1);
                if (this.selectedVolunteeringPontualTypes.length == 0)
                  this.addVolunteeringPontualTypeDate();
              });
          });
      else {
        this.selectedVolunteeringPontualTypes.splice(index, 1);
        if (this.selectedVolunteeringPontualTypes.length == 0)
          this.addVolunteeringPontualTypeDate();
      }
    },
    addVolunteeringRecurrentTypeDate() {
      let volunteeringDateUntil = "";
      if (this.selectedVolunteeringRecurrentTypes.length == 0)
        volunteeringDateUntil = moment(new Date())
          .add(1, "days")
          .format("YYYY-MM-DD");
      else
        volunteeringDateUntil = moment(
          this.selectedVolunteeringRecurrentTypes[
            this.selectedVolunteeringRecurrentTypes.length - 1
          ].volunteeringDateUntil,
          "YYYY-MM-DD"
        )
          .add(1, "days")
          .format("YYYY-MM-DD");

      this.selectedVolunteeringRecurrentTypes.push({
        volunteeringDateUntil: volunteeringDateUntil,
        weeklyHours: 10,
        minHoursDedication: 3,
        maxHoursDedication: 10,
        initialTime: "08:00",
        finalTime: "10:00",
        volunteeringRecurrentWeekDays: JSON.parse(
          JSON.stringify(this.weekDays)
        ),
      });
    },
    removeVolunteeringRecurrentTypeDate(selectedVolunteeringRecurrentType, index) {
      if (selectedVolunteeringRecurrentType.id > 0) {
        this.volunteeringAttributeOfferService
          .deleteVolunteeringRecurrentType(selectedVolunteeringRecurrentType.id)
          .then(() => {

            this.selectedVolunteeringRecurrentTypes.splice(index, 1);
            if (this.selectedVolunteeringRecurrentTypes.length == 0)
              this.addVolunteeringRecurrentTypeDate();

            // this.volunteeringAttributeOfferService
            //   .findByVolunteeringId(this.volunteeringId)
            //   .then((volunteeringAttributeOffers) => {
            //     this.volunteering.volunteeringAttributeOffers = volunteeringAttributeOffers;
            //     this.volunteeringAttributeOffers = volunteeringAttributeOffers;
            //     this.selectedVolunteeringAttributeOffer = this.volunteeringAttributeOffers.find((v) => v.id == this.selectedVolunteeringAttributeOffer.id);
            //     this.selectedVolunteeringPontualTypes = this.selectedVolunteeringAttributeOffer.volunteeringPontualTypes;
            //     this.selectedVolunteeringRecurrentTypes = this.selectedVolunteeringAttributeOffer.volunteeringRecurrentTypes;

            //     if (this.selectedVolunteeringRecurrentTypes.length == 0)
            //       this.addVolunteeringRecurrentTypeDate();
            //   });
          });
      } else {
        this.selectedVolunteeringRecurrentTypes.splice(index, 1);
        if (this.selectedVolunteeringRecurrentTypes.length == 0)
          this.addVolunteeringRecurrentTypeDate();
      }
    },

    //foto da vaga
    getImage() {
      if (
        this.selectedVolunteeringAttributeOffer == null ||
        this.selectedVolunteeringAttributeOffer.id == 0
      )
        return "";

      return "url(" + this.selectedVolunteeringAttributeOffer.file.path + ")";
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();

      this.selectedVolunteeringAttributeOffer.file = new File();
      this.selectedVolunteeringAttributeOffer.file.name = file.name;

      reader.onload = function () {
        this.selectedVolunteeringAttributeOffer.file.path = reader.result;
        this.selectedVolunteeringAttributeOffer.file.public = true;
        this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
      }.bind(this);

      reader.readAsDataURL(file);
    },

    //endereco
    searchCEP() {
      if (
        this.selectedVolunteeringAttributeOffer.address.postalCode == "" ||
        this.selectedVolunteeringAttributeOffer.address.postalCode.length < 8
      )
        return false;

      this.loaderInput = true;
      this.locationService
          .searchCEP(this.selectedVolunteeringAttributeOffer.address.postalCode)
          .then(this.searchCEPCallback)
          .finally(() => this.loaderInput = false)
      this.$viaCep
        .buscarCep(this.selectedVolunteeringAttributeOffer.address.postalCode)
        .then(this.searchCEPCallback);
    },
    searchCEPCallback(obj) {
      if (!obj.erro) {
        if (obj.street)
          this.selectedVolunteeringAttributeOffer.address.street =
            obj.street;
        if (obj.neighborhood)
          this.selectedVolunteeringAttributeOffer.address.neighborhood =
            obj.neighborhood;
        this.selectedVolunteeringAttributeOffer.address.city = obj.city;
        this.selectedVolunteeringAttributeOffer.address.state = obj.state;
        this.cepValidation = true;
      } else {
        this.selectedVolunteeringAttributeOffer.address.street = "";
        this.selectedVolunteeringAttributeOffer.address.neighborhood = "";
        this.selectedVolunteeringAttributeOffer.address.city = "";
        this.selectedVolunteeringAttributeOffer.address.state = "";
        this.cepValidation = false;
      }
      this.loaderInput = false;
    },

    // Validation
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step5", true);
    },
    validateStep() {
      let valid = this.isValid(false);

    },
    validate(performMethods) {
      let valid = true;

      if (
        (!this.selectedVolunteeringPontualTypes ||
          this.selectedVolunteeringPontualTypes.length == 0) &&
        (this.selectedVolunteeringRecurrentTypes ||
          this.selectedVolunteeringPontualTypes.length == 0)
      ) {
        valid = false;
      }

      if (
        this.selectedVolunteeringAttributeOffer.file.path ||
        this.selectedVolunteeringAttributeOffer.file.path.length == 0
      ) {
        valid = false;
      }

      return valid;
    },
    showValidationFailMessageMethod(invalidElements) {
      if (
        invalidElements.length > 1 ||
        (invalidElements.length == 1 && invalidElements[0] != "odsListInput")
      ) {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não preencheu todos os campos"
        );
      } else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Escolha ao menos um Objetivo de Desenvolvimento Sustentável"
        );
      }
    },
    formatDate(currentDate) {
      return format(currentDate, "DD/MM/YYYY", {
        locale: require("date-fns/locale/pt")
      });
    },
    async checkForm () {
      if (this.volunteering.volunteeringAttributeOffers && this.volunteering.volunteeringAttributeOffers.length > 0) this.formIsOk = true
      else this.formIsOk = false
    }
  },
};
</script>