<template>
  <div>
    <v-layout row wrap pa-4 wrap-dash pf>
      <!-- sm7 md8 -->
      <v-flex sm12>
        <div class="my-panel">
          <div class="display-flex">
            <div class="photo-user-panel">
              <img :src="avatar" alt />
            </div>
            <div class="info-user-panel pl-4">
              <h2>{{ personFullName }}</h2>
              <div>
                <Icon name="fal fa-envelope" size="16" colorFont="rgba(255, 255, 255, .5)" />
                {{ personEmail }}
              </div>
              <div>
                <!-- se não tiver localização, inserir botão para completar perfil -->
                <router-link to="/perfil" tag="button" class="v-btn sm mx-0 mt-2 btn-white" v-if="personCity == 0">
                  <Icon name="fal fa-user-edit" size="16" />Complete seu perfil
                </router-link>
                <!-- se não tiver localização -->
                <span v-if="personCity != 0">
                  <Icon name="far fa-map-marker-alt" size="16" colorFont="rgba(255, 255, 255, .5)" />
                  {{ personCity }}, {{ personState }}
                </span>
              </div>
              <div v-show="false" class="item-status-profile">
                <div>
                  <Icon name="fal fa-star" size="16" colorFont="#C3C6D5" />12
                </div>
                <div>
                  <Icon name="fal fa-hands-heart" size="16" colorFont="#C3C6D5" />3
                </div>
                <div>
                  <Icon name="fal fa-binoculars" size="16" colorFont="#C3C6D5" />12
                </div>
              </div>
            </div>
          </div>
          <div v-show="false" class="level-profile">
            <div class="points">
              <h2>Nível Prata</h2>
              <span> <strong>1200</strong> / 3500 pontos </span>
              <a href="javascript:;">Meus benefícios</a>
            </div>
            <div class="trophy-profile">
              <div class="chart-container" style="--value: 40">
                <Icon name="fal fa-trophy-alt" size="48" colorFont="#fff" />
                <svg class="chart">
                  <circle class="base" />
                  <circle class="pie" />
                </svg>
              </div>
              <svg style="height: 0; width: 0">
                <defs>
                  <linearGradient id="linear01" x1="0%" y1="0%" x2="50%" y2="0%">
                    <stop offset="0%" stop-color="#11F8BB" />
                    <stop offset="100%" stop-color="#11F8BB" />
                  </linearGradient>
                  <linearGradient id="linear02" x1="0%" y1="0%" x2="50%" y2="0%">
                    <stop offset="0%" stop-color="transparent" />
                    <stop offset="100%" stop-color="transparent" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout column wrap px-4 pb-4pt-0>
      <v-flex bg-white br-6>
        <div class="my-donations-header display-flex pa-4">
          <div>
            <Icon name="fal fa-hands-heart" size="30" class="mr-3" colorFont="#C3C6D5" />
            <h2 class="mb-0">Meu voluntariado</h2>
          </div>
          <div>
            <v-menu offset-y left>

              <!-- inserir if para empty state -->
              <v-list>
                <v-list-tile>
                  <v-list-tile-title>
                    <Icon class="fas fa-atlas" />Conselho
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile>
                  <!-- v-for="(item, index) in items" :key="index" @click>-->
                  <!-- <v-list-tile-title>{{ item.title }}</v-list-tile-title> -->
                  <v-list-tile-title>
                    <Icon class="fas fa-hands" />Instituição
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile>
                  <v-list-tile-title>
                    <Icon class="fas fa-user-tie" />Empresa
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-layout box-profile-father pf pt-0 wrap>
          <div class="d-flex" :key="subscriptionCardsKey">
            <VolunteerCard :showDeleted="true" :attributeOffer="volunteeringInfo"
              @cancelVolunteering="openCancelConfirmation" v-if="volunteeringInfo"/>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <AucAlert ref="confirmationComponent" title="Você tem certeza que deseja cancelar essa inscrição?"
      confirmButtonText="Sim" cancelButtonText="Não" @confirm="cancel" />
  </div>
</template>

<script type="plain/text">
import VolunteerCard from "./component/VolunteerCard.vue"
import PersonService from "@/scripts/services/person.service";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service.js"
import CommonHelper from "@/scripts/helpers/common.helper";
import VolunteeringService from "@/scripts/services/volunteering.service"
import AucAlert from "@/components/AucAlert.vue";
import md5 from "crypto-js/md5"

export default {
  components: {
    VolunteerCard,
    AucAlert,
  },
  data() {
    return {
      personService: new PersonService(),
      formatterHelper: new FormatterHelper(),
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      volunteeringService: new VolunteeringService(),
      person: null,
      volunteeringInfo: null,
      idToCancel: null,
      groupsOfOffers: null,
      showDeleted: false,
      subscriptionCardsKey: 0,
      avatar: null
    }
  },
  async created() {
    await this.getProfile();
  },
  computed: {
    personFullName() {
      let result = "";
      if (this.person && this.person.cnpj != null) {
        result = this.person.socialReason
      } else {
        if (this.person != null) {
          if (this.person.name != null) {
            result += this.person.name;
            if (this.person.lastName != null) result += " ";
          }
          if (this.person.lastName != null) result += this.person.lastName;
        }
      }
      return result;
    },
    personEmail() {
      if (this.person != null) return this.person.email;
      return "";
    },
    personCity() {
      if (this.person != null && this.person.address != null)
        return this.person.address.city;
      return "";
    },
    personState() {
      if (this.person != null && this.person.address != null)
        return this.person.address.state;
      return "";
    },
  },
  methods: {
    swal(title, message, type) {
      CommonHelper.swal(title, message, type);
    },
    async getProfileCallback(data) {
      data.birthDate = this.formatterHelper.formatDate(data.birthDate);
      this.person = data;
      this.getUrl()
      this.volunteeringInfo = await this.getMyVolunteering()
    },
    async getProfile() {
      this.personService.getProfile(await this.getProfileCallback)
    },
    getUrl() {
      let email = md5(this.person.email);
      let name;

      if (this.person != null && this.person.cnpj) {
        name = this.person.socialReason;
      } else {
        name = this.person.name;
      }

      this.avatar = `https://www.gravatar.com/avatar/${email}?d=https%3A%2F%2Fui-avatars.com%2Fapi%2F${name}/300/f2f3f7/4c4d4f`;
    },
    async getMyVolunteering() {
      try {
        const offers = await this.volunteeringRegistrationOfferService.findUserRegistrationOffersbyUserId(this.person.userId);
        
        //Paralelize as chamadas assíncronas com logs detalhados
        await Promise.all(
          offers.map(async (offer) => {
            try {
              offer.volunteering = await this.volunteeringService.findInstitutionByVolunteeringId(offer.volunteeringId);

              offer.volunteeringInformation = await this.volunteeringService.findById(offer.volunteeringId);
            } catch (error) {
              console.error(`Error fetching data for offer ID ${offer.volunteeringId}:`, error);
            }
          })
        );

        // console.log('All volunteering data fetched:', offers);

        // Ordenação dos dados
        const sortedOffers = offers.sort((a, b) => (a.isDeleted < b.isDeleted ? -1 : 1));

        return sortedOffers;
      } catch (error) {
        console.error('Error in getMyVolunteering:', error);
      }
    },
    openCancelConfirmation(id) {
      this.idToCancel = id
      this.$refs.confirmationComponent.open();
    },
    async cancel() {
      await this.volunteeringRegistrationOfferService.Delete(this.idToCancel)
      this.swal('Cancelado', 'Vaga cancelada com sucesso!', 'success');
      this.volunteeringInfo = await this.getMyVolunteering()
      this.subscriptionCardsKey += 1
    },
    getDate(date) {
      const dateValue = new Date(date)
      return dateValue.getDate().toString().padStart(2, '0') + '/' + (dateValue.getMonth() + 1).toString().padStart(2, '0') + '/' + dateValue.getFullYear()
    },
  }
};
</script>
